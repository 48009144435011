<template lang="pug">
.swiper
  .swiper-container(
    :class="swiperClassName"
  )
    .swiper-wrapper
      .swiper-slide(
        v-for="(slideData, index) in swiperSlidesData"
        :key="index"
      )
        .swiper-slide-image(
          v-if="swiperType === 'image'"
          :style="`background-image:url(${slideData.image.url})`"
        )
        prismic-embed.swiper-slide-video(
          v-if="swiperType === 'video'"
          :field="slideData.embed"
        )
    .swiper-navigation
      button.swiper-navigation-prev
      button.swiper-navigation-next
    .swiper-scrollbar
</template>

<script>
import Swiper from 'swiper/bundle';

export default {
  name: 'Swiper',
  props: {
    swiperSlidesData: {
      type: Array,
      default() {
        return [];
      },
    },
    swiperClassName: {
      type: String,
      default: 'swiper-container',
    },
    swiperType: {
      type: String,
      default: 'image',
    },
  },
  mounted() {
    // eslint-disable-next-line
    const swiper = new Swiper(`.${this.swiperClassName}`, {
      spaceBetween: 8,
      breakpoints: {
        768: {
          spaceBetween: 24,
        },
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
      navigation: {
        nextEl: '.swiper-navigation-next',
        prevEl: '.swiper-navigation-prev',
      },
    });
  },
};
</script>

<style lang="scss">
@import '../../node_modules/swiper/swiper-bundle.css';
.swiper {
  padding-top: 48px;
  padding-bottom: 48px;
  @include mq($until: md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &-container {
    padding-right: 40%;
    padding-bottom: 8px;
    margin-right: -56px;
    @include mq($until: md) {
      padding-right: 25%;
      padding-bottom: 20px;
      margin-right: -30px;
    }
    .swiper-slide {
      opacity: .3;
      transition: .3s opacity ease;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $gradient-bluish-green;
        opacity: .3;
        transition: .3s opacity ease;
      }
      &-active {
        opacity: 1;
        &::after {
          opacity: 0;
          pointer-events: none;
        }
      }
      &-image {
        height: 0;
        padding-bottom: 50%;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      &-video {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        & > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .swiper-scrollbar {
      width: auto;
      right: 56px;
      left: 0;
      bottom: 0;
      height: 8px;
      border-radius: 0;
      background-color: $color-mystic;
      @include mq($until: md) {
        right: 30px;
        height: 4px;
      }
      &-drag {
        border-radius: 0;
        background: $gradient-bluish-green;
      }
    }

    .swiper-navigation {
      display: flex;
      padding: 19px 0;
      justify-content: flex-end;

      &-prev {
        width: 25px;
        height: 25px;
        background-position: 5px center;
        background-repeat: no-repeat;

        &.swiper-button-disabled {
          background-image: url('../assets/swiper/prev-non-active.svg');
        }

        &:not(.swiper-button-disabled) {
          background-image: url('../assets/swiper/prev-active.svg');
        }
      }

      &-next {
        width: 25px;
        height: 25px;
        background-position: -13px center;
        background-repeat: no-repeat;

        &.swiper-button-disabled {
          background-image: url('../assets/swiper/next-non-active.svg');
        }

        &:not(.swiper-button-disabled) {
          background-image: url('../assets/swiper/next-active.svg');
        }
      }
    }
  }
}
</style>
