<template lang="pug">
.chapter(:data-id="id")
  prismic-rich-text(
    class="chapter__title"
    :field="content.title"
  )
  prismic-rich-text(
    class="chapter__subtitle"
    :field="content.subtitle"
  )
  .chapter__body
    .chapter__body__item(
      v-for="(bodyItem, index) in content.body"
    )
      prismic-rich-text.text(
        v-if="bodyItem.slice_type === 'text'"
        :field="bodyItem.primary.content"
      )
      Swiper(
        v-else-if="bodyItem.slice_type === 'image_slider'"
        :swiper-class-name="`swiper-${id}-${index}`"
        :swiper-slides-data="bodyItem.items"
        :swiper-type="'image'"
      )
      Swiper(
        v-else-if="bodyItem.slice_type === 'video_slider'"
        :swiper-class-name="`swiper-${id}-${index}`"
        :swiper-slides-data="bodyItem.items"
        :swiper-type="'video'"
      )
      .quote(
        v-else-if="bodyItem.slice_type === 'quote'"
      )
        .quote__content
          img.quote__content__icon(src='@/assets/quote-icon.svg')
          prismic-rich-text.quote__content__text(:field="bodyItem.primary.text")
          prismic-rich-text.quote__content__caption(:field="bodyItem.primary.caption")
      .image(
        v-else-if="bodyItem.slice_type === 'image'"
      )
        prismic-image.image__img(:field="bodyItem.primary.image")
        prismic-rich-text.image__caption(:field="bodyItem.primary.caption")
      .image-tile(
        v-else-if="bodyItem.slice_type === 'image_tile'"
      )
        .image-tile__item(
          v-for="imageItem in bodyItem.items"
        )
          .image-tile__item__img(
            :style="`background-image: url(${imageItem.image.url})`"
          )
          prismic-rich-text.image-tile__item__caption(
            :field="imageItem.caption"
          )
      .team(
        v-else-if="bodyItem.slice_type === 'team'"
      )
        .team__member(
          v-for="teamMember in bodyItem.items"
        )
          .team__member__avatar
            .team__member__avatar__image(
              :style="`background-image: url(${teamMember.avatar.url})`"
            )
          p.team__member__name {{ teamMember.name }}
          p.team__member__position {{ teamMember.position }}
      .table(
        v-else-if="bodyItem.slice_type === 'table'"
        :class="{'table--separated-by-lines': bodyItem.primary.separated_by_lines}"
      )
        .table__row.table__row-titles
          .table__row__item.table__row__item-main {{ bodyItem.primary.main_column_title }}
          .table__row__item(
            v-for="tableRowItem in bodyItem.items"
          ) {{ tableRowItem.column_title }}
        .table__row.table__row-values(
          v-for="(tableValuesMainItem, index) in bodyItem.primary.main_column_values"
        )
          .table__row__item.table__row__item-main {{ tableValuesMainItem.text }}
          .table__row__item(
            v-for="tableRowItem in bodyItem.items"
          ) {{ tableRowItem.column_values[index].text }}
        .table__row.table__row-resume
          .table__row__item.table__row__item-main {{ bodyItem.primary.main_column_resume }}
          .table__row__item(
            v-for="tableRowItem in bodyItem.items"
          ) {{ tableRowItem.column_resume }}
      .chart(
        v-else-if="bodyItem.slice_type === 'chart'"
      )
        prismic-embed.chart__embed(
          :field="bodyItem.primary.embed"
        )
        prismic-rich-text.chart__caption(
          :field="bodyItem.primary.caption"
        )
      .video(
        v-else-if="bodyItem.slice_type === 'video'"
      )
        prismic-embed.video__embed(
          :field="bodyItem.primary.embed"
        )
  script(v-if="chartPresented" type='application/javascript').
    !function(e,i,n,s){var t="InfogramEmbeds",d=e.getElementsByTagName("script")[0];
    if(window[t]&&window[t].initialized)window[t].process&&window[t].process();
    else if(!e.getElementById(n)){var o=e.createElement("script");
    o.async=1,o.id=n,o.src="https://e.infogram.com/js/dist/embed-loader-min.js",
    d.parentNode.insertBefore(o,d)}}(document,0,"infogram-async");
</template>

<script>
import Swiper from './Swiper.vue';

export default {
  name: 'Chapter',
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Swiper,
  },
  computed: {
    chartPresented() {
      if (!this.content.body) {
        return false;
      }
      return this.content.body.some((bodyElement) => bodyElement.slice_type === 'chart');
    },
  },
};
</script>

<style lang="scss">
  .chapter {
    padding-top: 80px;
    &__subtitle {
      font-size: 24px;
      color: $color-teal-blue;
    }
    &__title,
    &__subtitle {
      margin-bottom: 32px;
    }
    &__title,
    &__subtitle,
    &__body__item > *:not(.swiper) {
      max-width: 1320px;
      padding-right: 520px;
      @include mq($until: lg) {
        padding-right: 340px;
      }
      @include mq($until: md) {
        padding-right: 0;
      }
    }
    &__body {
      &__item {
        .text {
          margin-top: 24px;
          h2 {
            margin-bottom: 20px;
          }
          h3 {
            margin-bottom: 16px;
          }
          .underlined {
            text-decoration: underline;
            color: $color-teal-blue;
          }
        }
        .quote {
          &__content {
            padding-top: 38px;
            padding-right: 48px;
            padding-bottom: 38px;
            padding-left: 48px;
            @include mq($until: md) {
              padding-right: 0;
              padding-left: 0;
            }
            &__icon {
              width: 27px;
              height: 27px;
              @include mq($until: md) {
                width: 36px;
                height: 36px;
              }
            }
            &__text {
              padding-top: 18px;
              padding-bottom: 28px;
              font-size: 32px;
              line-height: 1.25;
              font-weight: bold;
              color: $color-bondi-blue;
              @include mq($until: md) {
                font-size: 24px;
                padding-top: 10px;
              }
            }
          }
        }
        .image {
          padding-top: 16px;
          padding-bottom: 16px;
        }
        .image,
        .image-tile__item {
          &__img {
            margin-bottom: 16px;
            max-width: 600px;
            width: 100%;
          }
          &__caption {
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-top: 1px solid $color-bondi-blue;
          }
        }
        .image-tile {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          padding-top: 16px;
          padding-bottom: 16px;
          @include mq($until: md) {
            display: block;
            .image-tile__item + .image-tile__item {
              margin-top: 16px;
            }
          }
          &__item {
            &__img {
              height: 0;
              padding-bottom: 70%;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        .team {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          row-gap: 50px;
          column-gap: 10%;
          padding-top: 32px;
          padding-bottom: 32px;
          @include mq($until: xl) {
            grid-template-columns: repeat(3, 1fr);
          }
          @include mq($until: lg) {
            grid-template-columns: repeat(2, 1fr);
          }
          @include mq($until: md) {
            row-gap: 24px;
            grid-template-columns: repeat(3, 1fr);
          }
          @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
          }
          &__member {
            display: flex;
            flex-direction: column;
            &__avatar {
              padding-right: 10px;
              padding-left: 10px;
              margin-bottom: 12px;
              &__image {
                height: 0;
                padding-bottom: 100%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50%;
              }
            }
            &__name,
            &__position {
              color: $color-teal-blue;
              text-align: center;
            }
            &__name {
              font-weight: bold;
            }
          }
        }
        .table {
          padding-top: 40px;
          padding-bottom: 40px;
          @include mq($until: md) {
            padding-top: 24px;
            padding-bottom: 24px;
          }
          &__row {
            display: flex;
            &-titles {
              padding-top: 10px;
              padding-bottom: 10px;
              margin-bottom: 30px;
              border-bottom: 1px solid $color-heather;
              color: $color-teal-blue;
              font-weight: bold;
            }
            &-values {
              margin-bottom: 16px;
              font-weight: 500;
              @include mq($until: lg) {
                font-size: 14px;
              }
            }
            &-resume {
              margin-top: 30px;
              color: $color-bondi-blue;
              font-weight: bold;
              @include mq($until: lg) {
                font-size: 14px;
              }
            }
            &__item {
              flex: 1 1 0%;
              text-align: end;
              padding-left: 20px;
              @include mq($until: xl) {
                flex-basis: 100%;
              }
              &-main {
                flex-grow: 2.5;
                text-align: start;
                padding-left: 0;
                word-break: break-word;
              }
            }
          }
          &--separated-by-lines {
            .table__row {
              &-titles {
                border-bottom: none;
                margin-bottom: 0;
              }
              &-values {
                padding-top: 16px;
                border-top: 1px solid $color-heather;
                @include mq($until: md) {
                  padding-top: 8px;
                }
              }
              &-resume {
                margin-top: 0;
              }
              &__item {
                text-align: start;
                &-main {
                  flex-grow: 2;
                  @include mq($until: md) {
                    flex-grow: 1;
                  }
                }
              }
            }
          }
        }
        .chart {
          padding-top: 48px;
          padding-bottom: 48px;
          @include mq($until: md) {
            padding-top: 28px;
            padding-bottom: 28px;
          }
          &__embed {
            margin-bottom: 16px;
            @include mq($until: md) {
              margin-bottom: 28px;
            }
          }
          &__caption {
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-top: 1px solid $color-bondi-blue;
          }
        }
        .video {
          padding-top: 40px;
          padding-bottom: 40px;
          &__embed {
            position: relative;
            height: 0;
            padding-bottom: 56.25%;
            & > iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
</style>
