<template lang="pug">
aside.menu(
  :class="{'menu--fixed': fixed}"
)
  .menu__title
    p Efnisyfirlit
  .menu__list
    .menu__list__item(
      v-for="item in content"
      @click="moveToChapter(item.id)"
      :class="{'menu__list__item--active': item.isActive}"
    )
      ProgressCircle(
        :progress-percentage="item.progressPercentage"
      )
      p.menu__list__item__index {{ item.index }}
      p.menu__list__item__title {{ item.title }}
</template>

<script>
import ProgressCircle from './ProgressCircle.vue';

export default {
  name: 'Menu',
  props: {
    content: {
      type: Array,
      defalut() {
        return [];
      },
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProgressCircle,
  },
  methods: {
    moveToChapter(id) {
      this.$emit('moveToChapter', id);
    },
  },
};
</script>

<style lang="scss">
.menu {
  width: 100%;
  padding-top: 80px;
  position: absolute;
  align-self: flex-end;
  z-index: 100;
  overflow-y: overlay;
  &--fixed {
    position: fixed;
    top: 0;
  }
  @include mq($until: lg) {
    max-width: 340px;
    padding-left: 60px;
    padding-right: 0;
  }
  @include mq($from: md) {
    max-width: 280px;
    margin-right: 120px;
    margin-left: 120px;
  }
  @include mq($until: md) {
    max-width: none;
    padding-top: 130px;
    padding-right: 30px;
    padding-left: 30px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-athens-gray;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    color: $color-teal-blue;
  }
  &__list {
    padding-top: 36px;
    &__item {
      position: relative;
      display: flex;
      align-items: baseline;
      padding-left: 46px;
      margin-bottom: 24px;
      font-size: 16px;
      color: $color-bali-hai;
      cursor: pointer;
      .progress-circle {
        display: none;
      }
      &__index {
        font-size: 12px;
        transition: all .1s ease;
      }
      &__title {
        padding-left: 10px;
        transition: all .1s ease;
      }
      &--active {
        font-weight: bold;
        color: $color-bondi-blue;
        .progress-circle {
          display: block;
        }
        .menu__list__item__index {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
