import Vue from 'vue';
import PrismicVue from '@prismicio/vue';
import App from './App.vue';
// eslint-disable-next-line
import styles from './styles/index.scss';

const endpoint = 'https://samorka-arsskyrsla-2023.prismic.io/api/v2';

Vue.use(PrismicVue, {
  endpoint,
});

new Vue({
  render: (h) => h(App),
}).$mount('#app');
